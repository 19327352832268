
    @use '@angular/material' as mat;
    @use 'themes/config/m3-palette' as palette;
    @use 'variables' as var;
    @use 'mixins';

    @mixin iconPadding($x, $y) {
      &:has(.material-icons, mat-icon, [matButtonIcon], .icon) {
        padding: $y $x $y ($x - 8px);

        &.icon-end {
          padding-left: $x;
          padding-right: $x - 8px;
        }
      }
    }

    sb-button {
      max-width: 100%;

      a {
        text-decoration: none;
        color: inherit;
      }

      button.mdc-button {
        --mdc-filled-button-container-shape: #{var.$button-radius};
        --mdc-protected-button-container-shape: #{var.$button-radius};
        --mdc-unelevated-button-container-shape: #{var.$button-radius};
        --mdc-outlined-button-container-shape: #{var.$button-radius};
        --mdc-text-button-container-shape: #{var.$button-radius};
        overflow: hidden;
        height: auto !important;
        padding: var.$button-padding-y var.$button-padding-x;
        line-height: var.$button-line-height;
        transition: all 200ms cubic-bezier(0.2, 0, 0, 1);

        @include iconPadding(var.$button-padding-x, var.$button-padding-y);

        &.sm:not(.square-button) {
          padding: var.$button-padding-y-sm var.$button-padding-x-sm;
          font-size: var.$button-font-size-sm;
          line-height: var.$button-line-height-sm;
          @include iconPadding(var.$button-padding-x-sm, var.$button-padding-y-sm);
        }

        .mat-mdc-button-touch-target {
          height: 100% !important;
        }

        .mdc-button__label {
          max-width: 100%;
          @include mixins.truncate-line();
        }

        mat-icon.mat-icon {
          flex-shrink: 0;
          height: var.$button-icon-size;
          width: var.$button-icon-size;
          margin-right: var.$button-icon-margin;

          &.icon-end {
            margin-right: 0;
            margin-left: var.$button-icon-margin;
          }
        }

        &.square-button {
          --mdc-outlined-button-container-shape: #{var.$button-radius-square};
          padding: var.$button-padding-square-y var.$button-padding-square-x;

          @include iconPadding(var.$button-padding-square-x, var.$button-padding-square-y);
        }

        &.menu-button {
          --mdc-outlined-button-container-shape: #{var.$button-radius-menu};
          padding: var.$button-padding-menu;

          mat-icon.menu-arrow {
            height: 24px;
            width: 24px;
            margin-right: 0;
            flex-shrink: 0;
          }
        }

        &.mdc-button--unelevated:hover {
          box-shadow: var(--mdc-protected-button-container-elevation);
        }
      }

      button.mat-mdc-fab {
        --mdc-fab-container-shape: #{var.$fab-radius};
      }

      button.mat-mdc-mini-fab {
        /* --mdc-fab-container-shape: #{var.$fab-mini-radius}; */
        --mdc-fab-small-container-color: #{var.$fab-background-primary-light};
        /* mat.get-theme-color(palette.$light-theme, surface) */
      }

      &.opened {
        button.mdc-button.menu-button mat-icon.menu-arrow {
          transform: rotate(180deg);
        }
      }
    }
  