// This mixin will ensure that lines that overflow the container will hide the overflow and
// truncate neatly with an ellipsis.
@mixin truncate-line() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// This mixin ensures an element spans to fill the nearest ancestor with defined positioning.
@mixin fill {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

// This mixin ensures an element spans to fill the nearest ancestor with defined positioning.
@mixin base-colors($color, $background) {
  background: $background;
  color: $color;
}
