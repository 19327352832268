@use "sass:map";
@use "sass:color";
@use "sass:math";
@use "@angular/material" as mat;
@use "themes/config/palette";

// TODO: define variables for dark theme in palette.scss and use them here

/**
 * Breakpoints
 */
$screen-breakpoints: (
  sm: "600px",
  md: "840px",
  lg: "1280px",
  xl: "1920px",
  "2xl": "1920px",
);

/**
 * Base sizes
 */
$base-spacer: 4px;
$base-radius: 4px;

/**
 * Colors
 */
$primary-light: map.get(palette.$primary, 600);
$primary-dark: map.get(palette.$primary, 200);
$app-background-light: map.get(palette.$neutral, 60);
$app-background-dark: map.get(palette.$neutral, 940);

/**
 * Layout
 */

// App Topbar
$topbar-height: 65px;
$app-topbar-brand-margin-left: 12px;
$app-topbar-brand-margin-left-md: 24px;
$app-topbar-brand-img-height: 30px;

// Sidebar
// ⚠ Sidebar width is dynamic so its collapsed and expanded width is defined in initial status of UI store
$nav-list-padding-x: $base-spacer * 4;
$nav-list-padding-y: $base-spacer * 3;

// Nav List Items
$item-status-overlay-background-color: map.get(palette.$secondary, 900);

$nav-item-height: 40px;
$nav-item-min-width: $nav-item-height;
$nav-item-padding-y: 0;
$nav-item-padding-x: $base-spacer * 2;
$nav-item-space-after: $nav-item-height;
$nav-item-space-between: $base-spacer;
$nav-item-radius: $base-radius * 5;
$nav-item-color: map.get(palette.$neutral, 600);
$nav-item-color-active: map.get(palette.$secondary, 900);
$nav-item-background-color-active: map.get(palette.$secondary, 100);
$nav-item-overlay-opacity-hover: 0.08;
$nav-item-overlay-opacity-focus: 0.12;
$nav-item-icon-size: 24px;
$nav-item-label-padding-left: $base-spacer * 5;

// Nav List Sub Items
$nav-subitem-height: 34px;
$nav-subitem-min-width: $nav-subitem-height;
$nav-subitem-padding-x: 3px;
$nav-subitem-padding-x-expanded: math.div($base-spacer * 5, 2);
$nav-subitem-space-between: $base-spacer;
$nav-subitem-icon-size: 18px;
$nav-subitem-overlay-opacity-active: $nav-item-overlay-opacity-hover;
$nav-subitem-overlay-opacity-hover: $nav-item-overlay-opacity-focus;
$nav-subitem-overlay-opacity-focus: 0.16;

// Main Page (inside sb-view component context)
$main-page-padding: $base-spacer * 2;
$view-header-padding-top: $base-spacer * 6;
$view-header-margin-bottom: $base-spacer * 6;
$view-toolbar-padding-y: 0;
$view-toolbar-padding-x: $base-spacer * 3;
$view-toolbar-padding-x-md: $base-spacer * 6;
$view-content-padding-y: 0;
$view-content-padding-x: $base-spacer * 3;
$view-content-padding-x-md: $base-spacer * 6;

// Navigation bar
$navbar-height: 74px;
$navbar-padding: $base-spacer * 6 0 10px;
$navbar-item-space-between: math.div($base-spacer * 5, 2);

/**
 * Elevations
 */
$base-elevation: 0px 1px 2px rgba(0, 0, 0, 0.3);
$elevation-light: $base-elevation, 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
$elevation-dark: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), $base-elevation;
$elevation-medium-light: $base-elevation, 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
$elevation-medium-dark: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), $base-elevation;


/**
 * Surfaces
 */
$surface-light: map.get(palette.$neutral, 20);
$surface-dark: map.get(palette.$neutral, 950);
$surface-container-light: map.get(palette.$neutral, 60);
$surface-container-dark: map.get(palette.$neutral, 880);
$surface-container-low-light: map.get(palette.$neutral, 40);
$surface-container-low-dark: $app-background-dark;
$surface-variant-light: map.get(palette.$neutral-variant, 100);

/**
 * Text
 */
$text-light: map.get(palette.$neutral, 900);
$text-dark: map.get(palette.$neutral, 200);
$text-content-light: map.get(palette.$neutral-variant, 700);
$text-content-dark: map.get(palette.$neutral-variant, 200);
$text-disabled-light: color.adjust(map.get(palette.$neutral, 900), $alpha: -0.62);
$text-disabled-dark: color.adjust(map.get(palette.$neutral, 200), $alpha: -0.62);

/**
 * Buttons
 */
$button-radius: 100px;
$button-radius-square: $base-radius * 2;
$button-radius-menu: $base-radius;
$button-disabled-light: color.adjust(map.get(palette.$neutral, 900), $alpha: -0.88);
$button-disabled-dark: color.adjust(map.get(palette.$neutral, 900), $alpha: -0.88);
$button-border-light: map.get(palette.$neutral-variant, 500);
$button-border-dark: map.get(palette.$neutral-variant, 400);
$button-background-outlined-light: white;
$button-background-outlined-dark: map.get(palette.$neutral, 900);
$button-background-tonal-light: map.get(palette.$secondary, 100);
$button-background-tonal-dark: map.get(palette.$secondary, 700);
$button-background-menu-light: $surface-light;
$button-background-menu-dark: $surface-dark;
$button-background-menu-hover-light: map.get(palette.$neutral, 900);
$button-background-menu-hover-dark: map.get(palette.$neutral, 200); // todo
$button-background-square-hover-light: palette.$on-secondary-container;
$button-background-square-hover-dark: $button-background-square-hover-light; // todo
$button-background-square-light: $surface-light;
$button-background-square-dark: $surface-dark;
$button-color-tonal-light: map.get(palette.$secondary, 900);
$button-color-tonal-dark: map.get(palette.$secondary, 100);
$button-color-square-light: $text-light;
$button-color-square-dark: $text-dark; // todo
$button-color-menu-light: $text-light;
$button-color-menu-dark: $text-dark; // todo
$button-padding-y: 10px;
$button-padding-x: 24px;
$button-padding-y-sm: 5px;
$button-padding-x-sm: 14px;
$button-padding-square-y: 5px;
$button-padding-square-x: 15px;
$button-padding-menu: 8px 0 8px 12px;
$button-icon-size: 20px;
$button-icon-margin: $base-spacer * 2;
$button-line-height: 20px;
$button-line-height-sm: 1.2;
$button-font-size-sm: 13px;

// FABs
$fab-radius: $base-radius * 4;
$fab-mini-radius: $base-radius * 3;
$fab-background-primary-light: map.get(palette.$neutral, 80);
$fab-background-primary-dark: map.get(palette.$neutral, 830);
$fab-background-primary-hover-light: map.get(palette.$primary, A400);
$fab-background-primary-hover-dark: map.get(palette.$neutral, 200); // todo
$fab-elevation-light: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);
$fab-elevation-hover-light: 0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3);

/**
 * Cards
 */
$card-radius: $base-radius * 3;
$card-border-light: map.get(palette.$neutral-variant, 200);
$card-border-dark: map.get(palette.$neutral-variant, 700);
$card-flat-light: map.get(palette.$neutral, 60);
$card-flat-dark: map.get(palette.$neutral-variant, 940);
$card-primary-light: map.get(palette.$neutral, 60);
$card-primary-dark: map.get(palette.$neutral-variant, 940);
$card-content-text-light: $text-content-light;
$card-content-text-dark: $text-content-dark;
$card-subtitle-font-weight: 400;
$card-header-padding: 12px 16px;
$card-content-padding: 16px;
$card-subtitle-margin-top: 4px;

/**
 * Speech bubbles
 */
$speech-bubble-border-light: transparent;
$speech-bubble-border-dark: transparent;
$speech-bubble-background-light: map.get(palette.$secondary, 100);
$speech-bubble-background-dark: map.get(palette.$secondary, 700);

/**
 * Dialogs
 */
$dialog-radius: $base-radius * 7;
$dialog-background-light: map.get(palette.$neutral, 80);
$dialog-background-dark: map.get(palette.$neutral, 830);
$card-title-text-light: $text-light;
$card-title-text-dark: $text-dark;
$card-content-text-light: $text-content-light;
$card-content-text-dark: $text-content-dark;
$dialog-header-padding: 24px 24px 16px;
$dialog-content-padding: 0 24px;
$dialog-actions-padding: 24px;
$dialog-header-font-size: 24px;
$dialog-header-font-weight: 500;
$dialog-header-line-height: 32px;

/**
 * Scrollbar
 */
$scrollbar-thumb-light: map.get(palette.$secondary, 200);
$scrollbar-thumb-dark: map.get(palette.$secondary, 800);
$scrollbar-width: 4px;
$scrollbar-height: 6px;
$scrollbar-radius: $base-radius;

/**
 * Snackbars
 */
$snackbar-radius: $base-radius;
$snackbar-background-light: map.get(palette.$neutral, 800);
$snackbar-background-dark: map.get(palette.$neutral, 100);
$snackbar-action-text-light: $primary-dark;
$snackbar-action-text-dark: $primary-light;
$snackbar-label-font-weight: 500;

/**
 * Border radius
 */
$menu-panel-radius: $base-radius * 5;

/**
 * Avatar
 */
$avatar-size-base: 3rem;
$avatar-size-xxs: 1rem;
$avatar-size-xs: 1.5rem;
$avatar-size-sm: 2.5rem;
$avatar-size-lg: 3.75rem;
$avatar-size-xl: 5.125rem;
$avatar-size-xxl: 8rem;
$avatar-border-color: map.get(palette.$secondary, 200);

/**
 * Paths
 */
$path-to-img: "/assets/img";

/**
 * Form fields
 */
$form-field-radius: $base-radius * 2;
$form-field-filled-background-light: map.get(palette.$neutral, 100);
$form-field-filled-background-dark: map.get(palette.$neutral, 780);
$form-field-filled-background-disabled-light: color.adjust(map.get(palette.$neutral, 900), $alpha: -0.96);
$form-field-filled-background-disabled-dark: color.adjust(map.get(palette.$neutral, 200), $alpha: -0.96);
$form-field-filled-text-disabled-light: color.adjust(map.get(palette.$neutral, 900), $alpha: -0.62);
$form-field-filled-text-disabled-dark: color.adjust(map.get(palette.$neutral, 200), $alpha: -0.62);

/**
 * Tabs
 */
$tabs-border-light: map.get(palette.$neutral, 100);
$tabs-border-dark: map.get(palette.$neutral, 780);

/**
 * Table
 */
$table-background-hover-light: palette.$secondary-container;
$table-background-hover-dark: map.get(palette.$secondary, 800);
$table-toolbar-bg-light: map.get(palette.$neutral, 100);
$table-toolbar-bg-dark: map.get(palette.$neutral, 780);
$table-toolbar-chip-bg-light: map.get(palette.$neutral, 200);
$table-toolbar-chip-bg-dark: map.get(palette.$neutral, 700);
$table-toolbar-border-radius: $base-radius * 3;

/**
 * Badge
 */
$badge-font-size: 14px;
$badge-font-weight: 500;
$badge-line-height: 20px;
$badge-padding: 4px 4px 4px 6px;
$badge-radius: 10px;
$badge-icon-padding-left: 4px;
$badge-icon-size: 20px;

$badge-sm-font-size: 11px;
$badge-sm-font-weight: 500;
$badge-sm-line-height: 16px;
$badge-sm-padding: 4px 4px 4px 6px;
$badge-sm-radius: 8px;
$badge-sm-icon-padding-left: 4px;
$badge-sm-icon-size: 20px;

$badge-primary-background-light: palette.$primary-container;
$badge-primary-background-dark: map.get(palette.$primary, 800);
$badge-primary-text-light: palette.$on-primary-container;
$badge-primary-text-dark: map.get(palette.$primary, 100);

$badge-secondary-background-light: palette.$secondary-container;
$badge-secondary-background-dark: map.get(palette.$secondary, 800);
$badge-secondary-text-light: palette.$on-secondary-container;
$badge-secondary-text-dark: map.get(palette.$secondary, 100);

$badge-success-background-light: palette.$success-container;
$badge-success-background-dark: map.get(palette.$success, 800);
$badge-success-text-light: palette.$on-success-container;
$badge-success-text-dark: map.get(palette.$success, 100);

$badge-warning-background-light: palette.$warning-container;
$badge-warning-background-dark: map.get(palette.$warning, 800);
$badge-warning-text-light: palette.$on-warning-container;
$badge-warning-text-dark: map.get(palette.$warning, 100);

$badge-error-background-light: palette.$error-container;
$badge-error-background-dark: map.get(palette.$error, 900);
$badge-error-text-light: palette.$on-error-container;
$badge-error-text-dark: map.get(palette.$error, 100);

$badge-neutral-background-light: map.get(palette.$neutral-variant, 100);
$badge-neutral-background-dark: map.get(palette.$neutral-variant, 900);
$badge-neutral-text-light: map.get(palette.$neutral-variant, 900);
$badge-neutral-text-dark: map.get(palette.$neutral-variant, 100);


/**
 * Alert
 */
$alert-radius: $base-radius * 3;
$alert-padding-x: $base-spacer * 4;
$alert-padding-y: $base-spacer * 3;

$alert-success-background-light: palette.$success-container;
$alert-success-background-dark: map.get(palette.$success, 800);
$alert-success-text-light: palette.$on-success-container;
$alert-success-text-dark: map.get(palette.$success, 100);

$alert-warning-background-light: palette.$warning-container;
$alert-warning-background-dark: map.get(palette.$warning, 800);
$alert-warning-text-light: palette.$on-warning-container;
$alert-warning-text-dark: map.get(palette.$warning, 100);

$alert-error-background-light: palette.$error-container;
$alert-error-background-dark: map.get(palette.$error, 900);
$alert-error-text-light: palette.$on-error-container;
$alert-error-text-dark: map.get(palette.$error, 100);

$alert-primary-background-light: palette.$primary-container;
$alert-primary-background-dark: map.get(palette.$primary, 900);
$alert-primary-text-light: palette.$on-primary-container;
$alert-primary-text-dark: palette.$on-primary;

$alert-secondary-background-light: palette.$secondary-container;
$alert-secondary-background-dark: map.get(palette.$secondary, 900);
$alert-secondary-text-light: palette.$on-secondary-container;
$alert-secondary-text-dark: palette.$on-secondary;

$alert-neutral-background-light: map.get(palette.$neutral-variant, 100);
$alert-neutral-background-dark: map.get(palette.$neutral-variant, 900);
$alert-neutral-text-light: map.get(palette.$neutral-variant, 900);
$alert-neutral-text-dark: map.get(palette.$neutral-variant, 100);

/**
 * Datepicker
 */
$datepicker-in-range-background-light: map.get(palette.$secondary, 100);
$datepicker-in-range-background-dark: map.get(palette.$secondary, 700);

/**
 * Lists
 */
$list-item-hover-background-light: map.get(palette.$secondary, 100);
$list-item-hover-background-dark: map.get(palette.$secondary, 700);

/**
 * Icons
 */
$icon-color-light: map.get(palette.$neutral, 600);
$icon-color-dark: map.get(palette.$neutral, 400);
