@use "sass:map";

$white: #ffffff;
$black: #000000;

// Key colors
$key-colors: (
  "primary": "#06667C",
  "tertiary": "#F37A6F",
  "neutral": "#EDF2F9",
);

/**
  * Primary palette
 */

$on-primary: $white;
$on-primary-container: #001f28;

$primary: (
  50: #dcf5ff,
  100: #b4ebff,
  200: #5ad5f9,
  300: #35b9dd,
  400: #009ebf,
  500: #00829e,
  600: #00677e,
  700: #004e5f,
  800: #003542,
  900: #001f28,
  A400: #6750a4,
  contrast: (
    50: $on-primary-container,
    100: $on-primary-container,
    200: $on-primary-container,
    300: $on-primary-container,
    400: $on-primary-container,
    500: $on-primary,
    600: $on-primary,
    700: $on-primary,
    800: $on-primary,
    900: $on-primary,
  ),
);

$primary-color: map.get($primary, 600);
$primary-container: map.get($primary, 100);

/**
  * Secondary palette
 */

$on-secondary: $white;
$on-secondary-container: #071e25;

$secondary: (
  50: #ddf5ff,
  100: #cee6f0,
  200: #b3cad4,
  300: #98afb8,
  400: #7d949d,
  500: #647b83,
  600: #4c626a,
  700: #344a52,
  800: #1d333b,
  900: #071e25,
  contrast: (
    50: $on-secondary-container,
    100: $on-secondary-container,
    200: $on-secondary-container,
    300: $on-secondary-container,
    400: $on-secondary-container,
    500: $on-secondary,
    600: $on-secondary,
    700: $on-secondary,
    800: $on-secondary,
    900: $on-secondary,
  ),
);

$secondary-color: map.get($secondary, 600);
$secondary-container: map.get($secondary, 100);

/**
  * Tertiary palette
 */

$on-tertiary: $white;
$on-tertiary-container: #071e25;

$tertiary: (
  50: #ddf5ff,
  100: #cee6f0,
  200: #b3cad4,
  300: #98afb8,
  400: #7d949d,
  500: #647b83,
  600: #4c626a,
  700: #344a52,
  800: #1d333b,
  900: #071e25,
  contrast: (
    50: $on-tertiary-container,
    100: $on-tertiary-container,
    200: $on-tertiary-container,
    300: $on-tertiary-container,
    400: $on-tertiary-container,
    500: $on-tertiary,
    600: $on-tertiary,
    700: $on-tertiary,
    800: $on-tertiary,
    900: $on-tertiary,
  ),
);

$tertiary-color: map.get($tertiary, 600);
$tertiary-container: map.get($tertiary, 100);

/**
  * Error palette
 */
$on-error: $white;
$on-error-container: #410002;

$error: (
  50: #ffedea,
  100: #ffdad6,
  200: #ffb4ab,
  300: #ff897d,
  400: #ff5449,
  500: #de3730,
  600: #ba1a1a,
  700: #93000a,
  800: #690005,
  900: #410002,
  contrast: (
    50: $on-error-container,
    100: $on-error-container,
    200: $on-error-container,
    300: $on-error-container,
    400: $on-error-container,
    500: $on-error,
    600: $on-error,
    700: $on-error,
    800: $on-error,
    900: $on-error,
  ),
);

$error-color: map.get($error, 600);
$error-container: map.get($error, 100);

/**
  * Warning palette
 */
$on-warning: $white;
$on-warning-container: #211b00;

$warning: (
  50: #fff1ba,
  100: #ffe253,
  200: #e1c639,
  300: #c4aa1a,
  400: #a79000,
  500: #8a7600,
  600: #6d5e00,
  700: #524600,
  800: #393000,
  900: #211b00,
  contrast: (
    50: $on-warning-container,
    100: $on-warning-container,
    200: $on-warning-container,
    300: $on-warning-container,
    400: $on-warning-container,
    500: $on-warning,
    600: $on-warning,
    700: $on-warning,
    800: $on-warning,
    900: $on-warning,
  ),
);

$warning-color: map.get($warning, 600);
$warning-container: map.get($warning, 100);

/**
  * Success palette
 */
$on-success: $white;
$on-success-container: #111f00;

$success: (
  50: #d4ff94,
  100: #c5f185,
  200: #aad46c,
  300: #90b854,
  400: #769d3c,
  500: #5d8224,
  600: #456809,
  700: #324f00,
  800: #213600,
  900: #111f00,
  contrast: (
    50: $on-success-container,
    100: $on-success-container,
    200: $on-success-container,
    300: $on-success-container,
    400: $on-success-container,
    500: $on-success,
    600: $on-success,
    700: $on-success,
    800: $on-success,
    900: $on-success,
  ),
);

$success-color: map.get($success, 600);
$success-container: map.get($success, 100);

/**
  * Neutral palette
 */
$on-neutral: $white;
$on-neutral-container: $black;

$neutral: (
  20: #f9f9fc,
  40: #f3f3f6,
  50: #f0f0f3,
  60: #edeef1,
  80: #e8e8eb,
  100: #e2e2e5,
  200: #c6c6c9,
  300: #aaabae,
  400: #8f9193,
  500: #75777a,
  600: #5d5e61,
  700: #454749,
  780: #333537,
  800: #2e3133,
  830: #282a2d,
  880: #1e2022,
  900: #1a1c1e,
  940: #111416,
  950: #0f1113,
  contrast: (
    50: $on-neutral-container,
    100: $on-neutral-container,
    200: $on-neutral-container,
    300: $on-neutral-container,
    400: $on-neutral-container,
    500: $on-neutral,
    600: $on-neutral,
    700: $on-neutral,
    800: $on-neutral,
    900: $on-neutral,
  ),
);

$neutral-variant: (
  50: #eaf2f6,
  100: #dbe4e8,
  200: #bfc8cc,
  300: #a4adb0,
  400: #899296,
  500: #70787c,
  600: #576063,
  700: #40484b,
  800: #293235,
  900: #151d20,
  contrast: (
    50: $on-neutral-container,
    100: $on-neutral-container,
    200: $on-neutral-container,
    300: $on-neutral-container,
    400: $on-neutral-container,
    500: $on-neutral,
    600: $on-neutral,
    700: $on-neutral,
    800: $on-neutral,
    900: $on-neutral,
  ),
);
